import React, { Component } from "react";
import {Link} from "react-router-dom";

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class BreadCrumb extends Component {
  render() {
    const {t} = this.props;
    return (
       <>
           {/* ===============  breadcrumb area start =============== */}
           <div className="breadcrumb-area">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="breadcrumb-wrap">
                               <h2>{t("header_8")}</h2>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           {/* ===============  breadcrumb area end =============== */}
       </>
    );
  }
}

export default withHooks(BreadCrumb);
