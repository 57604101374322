import React, { Component } from "react";
import {Link} from "react-router-dom";

import pd_thumb from "../../../assets/images/package/pd-thumb.png"
import pd_thumb_2 from "../../../assets/images/perubike_images/imagen_bohemian.jpg"
import pd_thumb_3 from "../../../assets/images/perubike_images/IMG_0109.f786fa7a.JPG"
import pd_thumb_4 from "../../../assets/images/perubike_images/IMG_0687.f6a30c65.JPG"
import pd_thumb_5 from "../../../assets/images/perubike_images/recorte_15.JPG"
import pd_thumb_6 from "../../../assets/images/perubike_images/IMG_1008.bb721c25.JPG"
import pd_thumb_7 from "../../../assets/images/perubike_images/IMG_1064.d25e88f6.JPG"
import pd_thumb_8 from "../../../assets/images/perubike_images/recorte_16.JPG"
import pd_thumb_9 from "../../../assets/images/perubike_images/recorte_17.JPG"
import pd_thumb_10 from "../../../assets/images/perubike_images/recorte_18.JPG"
import pd_thumb_11 from "../../../assets/images/perubike_images/recorte_19.JPG"
import pd_thumb_12 from "../../../assets/images/perubike_images/recorte_20.JPG"
import pd_thumb_13 from "../../../assets/images/perubike_images/recorte_21.JPG"
import pd_thumb_14 from "../../../assets/images/perubike_images/recorte_22.JPG"



import gallery1Img from "../../../assets/images/gallary/gl-1.png"
import gallery2Img from "../../../assets/images/gallary/gl-2.png"
import gallery4Img from "../../../assets/images/gallary/gl-4.png"
import gallery5Img from "../../../assets/images/gallary/gl-5.png"
import gallery6Img from "../../../assets/images/gallary/gl-6.png"

import galleryGxx1Img from "../../../assets/images/gallary/g-xxl-1.png"
import galleryGxx2Img from "../../../assets/images/gallary/g-xxl-2.png"
import galleryGxx3Img from "../../../assets/images/gallary/g-xxl-3.png"

import gallery17Img from "../../../assets/images/gallary/gl-17.png"
import gallery16Img from "../../../assets/images/gallary/gl-16.png"
import gallery14Img from "../../../assets/images/gallary/gl-14.png"

import galleryGxl1Img from "../../../assets/images/gallary/g-xl-1.png"
import galleryGxl2Img from "../../../assets/images/gallary/g-xl-2.png"
import galleryGxl3Img from "../../../assets/images/gallary/g-xl-3.png"
import galleryGxl4Img from "../../../assets/images/gallary/g-xl-4.png"

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_2  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_3  from "../../../assets/images/banners/banner_pachacamac.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_centro_historico.JPG";

import organizer  from "../../../assets/images/organizer.png";
import sidebarBannar  from "../../../assets/images/sidebar-banner.png";

import { SRLWrapper } from "simple-react-lightbox";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../../../i18n";
import { useTranslation } from "react-i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class LimaBohemian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date(),
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    const startDate =this.state.startDate;
    const {t} = this.props;

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area-lima-bohemian">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("centro_historico_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={pd_thumb_2} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>{t("pachacamac_56")}</h3>
                                        <h4>{t("centro_historico_2")}</h4>
                                    </div>
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                                {t("information")}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                                {t("our_gallary")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5 style={{"margin":"5px"}}>{t("centro_historico_3")}</h5>
                                                            <p>
                                                                {t("lima_bohemian_4")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"5px"}}>
                                                            {t("lima_bohemian_5")}
                                                            </h5>
                                                            <p>
                                                            <b>{t("lima_bohemian_6")}</b>
                                                            </p>
                                                            <p>
                                                            <b>{t("lima_bohemian_7")}</b>
                                                            </p>
                                                            <p>
                                                            {t("lima_bohemian_8")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"5px"}}>{t("lima_bohemian_9")}</h5>
                                                            <p>
                                                                <b>{t("lima_bohemian_10")}</b>{t("lima_bohemian_11")}
                                                            </p>
                                                            <p>
                                                                <b>{t("lima_bohemian_12")}</b>{t("lima_bohemian_13")}

                                                            </p>
                                                            <br/>

                                                        </div>
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_14")}</td>
                                                                    <td>{t("lima_bohemian_15")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_16")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />
                                                                            {t("lima_bohemian_17")}
                                                                            </li>
                                                                            <li> <i className="bx bx-check" />
                                                                            {t("lima_bohemian_18")}
                                                                            </li>
                                                                            <li> <i className="bx bx-x" /><b>{t("lima_bohemian_19")}</b></li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_20")}</td>
                                                                    <td>{t("lima_bohemian_21")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_22")}</td>
                                                                    <td>{t("lima_bohemian_23")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_24")}</td>
                                                                    <td>{t("lima_bohemian_25")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_26")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bohemian_27")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bohemian_28")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_29")}</td>
                                                                    <td>
                                                                    {t("lima_bohemian_30")}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_31")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bohemian_32")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_33")}</td>
                                                                    <td>
                                                                        <ul className="table-list-disallow">
                                                                            <li> <i className="bx bx-x" />{t("lima_bohemian_34")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_35")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("lima_bohemian_36")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bohemian_37")}</li>
                                                                            <li> <i className="bx bx-check" />{t("lima_bohemian_38")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("lima_bohemian_39")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bohemian_40")}</li>
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bohemian_41")}</li>
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("lima_bohemian_42")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                            <div className="single-grid">
                                                                <Link to={pd_thumb_3} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_3} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_4} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_4} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_9} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_9} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid mt-24">
                                                                <Link to={pd_thumb_5} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_5} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_6} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_6} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_10} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_10} alt="gallary-img" />
                                                                </Link>
                                                            </div>

                                                            <div className="single-grid mt-24">

                                                                <Link to={pd_thumb_7} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_7} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_8} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_8} alt="gallary-img" />
                                                                </Link>
                                                                <Link to={pd_thumb_11} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_11} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-2">
                                                                <Link to={pd_thumb_12}  className="main-gallary">
                                                                    <img src={pd_thumb_12} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_13}  className="single-grid-2 main-gallary mt-30">
                                                                    <img src={pd_thumb_13} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            <div className="single-grid-2 mt-24">
                                                                <Link to={pd_thumb_14}  className="main-gallary mt-30">
                                                                    <img src={pd_thumb_14} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("lima_bohemian_43")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" value={t("book_now")} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("popular_packages")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>{t("lima_bohemian_45")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_3} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/pachacamac`}>{t("lima_bohemian_48")}</Link>
                                                        </h3>
                                                        <h5><span>$187</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("lima_bohemian_51")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("per_person")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

export default withHooks(LimaBohemian);
