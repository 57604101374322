import React, { Component } from "react";
import {Link} from "react-router-dom";

import pd_thumb from "../../../assets/images/package/IMG_1470.jpg"
import pd_thumb_2 from "../../../assets/images/perubike_images/P1090073.6d990ab3.JPG"
import pd_thumb_3 from "../../../assets/images/perubike_images/recorte_23.JPG"
import pd_thumb_4 from "../../../assets/images/perubike_images/image2.b8edb4e0.JPG"
import pd_thumb_5 from "../../../assets/images/perubike_images/P1090017.dc6c746d.JPG"
import pd_thumb_6 from "../../../assets/images/perubike_images/recorte_24.JPG"


import pr_1 from "../../../assets/images/package/pr-1.png"

import gallery1Img from "../../../assets/images/gallary/gl-1.png"
import gallery2Img from "../../../assets/images/gallary/gl-2.png"
import gallery4Img from "../../../assets/images/gallary/gl-4.png"
import gallery5Img from "../../../assets/images/gallary/gl-5.png"
import gallery6Img from "../../../assets/images/gallary/gl-6.png"

import galleryGxx1Img from "../../../assets/images/gallary/g-xxl-1.png"
import galleryGxx2Img from "../../../assets/images/gallary/g-xxl-2.png"
import galleryGxx3Img from "../../../assets/images/gallary/g-xxl-3.png"

import gallery17Img from "../../../assets/images/gallary/gl-17.png"
import gallery16Img from "../../../assets/images/gallary/gl-16.png"
import gallery14Img from "../../../assets/images/gallary/gl-14.png"

import galleryGxl1Img from "../../../assets/images/gallary/g-xl-1.png"
import galleryGxl2Img from "../../../assets/images/gallary/g-xl-2.png"
import galleryGxl3Img from "../../../assets/images/gallary/g-xl-3.png"
import galleryGxl4Img from "../../../assets/images/gallary/g-xl-4.png"

import pm_sm_1  from "../../../assets/images/banners/banner_lima_bohemian.JPG";
import pm_sm_2  from "../../../assets/images/banners/banner_lima_bahia.jpg";
import pm_sm_3  from "../../../assets/images/banners/banner_pachacamac.JPG";
import pm_sm_4  from "../../../assets/images/banners/banner_centro_historico.JPG";

import organizer  from "../../../assets/images/organizer.png";
import sidebarBannar  from "../../../assets/images/sidebar-banner.png";

import { SRLWrapper } from "simple-react-lightbox";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class Pachacamac extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:new Date(),
        };
    }
    changeDatepickerHandeller=(date)=>{
        this.setState({ startDate: date });
    }

    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
        const startDate =this.state.startDate;

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area-pachacamac">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("centro_historico_1")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="package-details-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="package-details">
                                <div className="package-thumb">
                                    <img src={pd_thumb_2} alt="" />
                                </div>
                                <div className="package-header">
                                    <div className="package-title">
                                        <h3>Pachacamac</h3>
                                    </div>
                                </div>
                                <div className="package-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i className="flaticon-info" />
                                            {t("information")}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"> <i className="flaticon-gallery" />
                                            {t("our_gallary")}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-1">
                                                        <div className="p-overview">
                                                            <h5>{t("centro_historico_3")}</h5>
                                                            <p>
                                                                {t("pachacamac_1")}
                                                                {t("pachacamac_2")}
                                                                {t("pachacamac_4")}
                                                            </p>
                                                            <p>
                                                                {t("pachacamac_5")}
                                                            </p>
                                                            <p>
                                                                {t("pachacamac_6")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"4px"}}>{t("pachacamac_7")}</h5>
                                                            <b>{t("pachacamac_8")}</b>
                                                            <p>
                                                                {t("pachacamac_9")}
                                                            </p>

                                                            <p>
                                                                {t("pachacamac_10")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"4px"}}>{t("pachacamac_11")}</h5>
                                                            <b>{t("pachacamac_12")}</b>
                                                            <p>
                                                                {t("pachacamac_13")}
                                                            </p>
                                                            <p>
                                                                {t("pachacamac_14")}
                                                            </p>
                                                            <p>
                                                                {t("pachacamac_15")}
                                                            </p>
                                                            <br/>
                                                            <h5 style={{"margin":"4px"}}>{t("pachacamac_16")}</h5>
                                                            <b>{t("pachacamac_17")}</b>
                                                            <p>{t("pachacamac_18")}
                                                            </p>

                                                            <p>{t("pachacamac_19")}
                                                            </p>

                                                        </div>
                                                        <br/>
                                                        <div className="p-details-table">
                                                            <table className="table caption-top">
                                                                <tbody>
                                                                <tr>
                                                                    <td>{t("pachacamac_20")}</td>
                                                                    <td>{t("pachacamac_21")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_22")}</td>
                                                                    <td>{t("pachacamac_23")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_24")}</td>
                                                                    <td>{t("pachacamac_25")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_26")}</td>
                                                                    <td>{t("pachacamac_27")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_28")}</td>
                                                                    <td>{t("pachacamac_29")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_30")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("pachacamac_31")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_32")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_33")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("pachacamac_34")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_35")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_36")}</td>
                                                                    <td>
                                                                        <ul className="table-list-disallow">
                                                                            <li> <i className="bx bx-x" />{t("pachacamac_37")}</li>
                                                                            <li><i className="bx bx-x" /> {t("pachacamac_38")}</li>
                                                                            <li><i className="bx bx-x" /> {t("pachacamac_39")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_40")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li><i className="bx bx-check" />{t("pachacamac_41")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_42")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_43")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("pachacamac_44")}</td>
                                                                    <td>
                                                                        <ul className="table-list-allow">
                                                                            <li> <i className="bx bx-check" /> 
                                                                            {t("pachacamac_45")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_46")}</li>
                                                                            <li> <i className="bx bx-check" />{t("pachacamac_47")}</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div>
                                                            <p>
                                                            {t("pachacamac_48")}
                                                            </p>
                                                            <br/>
                                                            <p>
                                                            {t("pachacamac_49")}

                                                            </p>
                                                            <p>
                                                            {t("pachacamac_50")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="tab-contant-3">
                                                <SRLWrapper>
                                                <div className="row">
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="package-grid-one">
                                                            <div className="single-grid">
                                                                <Link to={pd_thumb_4} className="g-img-sm-1 main-gallary">
                                                                    <img src={pd_thumb_4} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_5} className="g-img-sm-2 main-gallary">
                                                                    <img src={pd_thumb_5} alt="gallary-img" />
                                                                </Link>

                                                                <Link to={pd_thumb_6} className="g-img-md main-gallary">
                                                                    <img src={pd_thumb_6} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="package-grid-two">
                                                            <div className="single-grid-2">
                                                                <Link to={pd_thumb_3}  className="main-gallary">
                                                                    <img src={pd_thumb_3} alt="gallary-img" />
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-d-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-form">
                                            <form>
                                                <h5 className="package-d-head">{t("pachacamac_51")}</h5>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="submit" value={t("pachacamac_52")} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="p-sidebar-cards mt-40">
                                            <h5 className="package-d-head">{t("pachacamac_53")}</h5>
                                            <ul className="package-cards">
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_2} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima_bahia`}>{t("pachacamac_54")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("pachacamac_55")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_1} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/lima-bohemian`}>{t("pachacamac_56")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("pachacamac_55")}</h5>
                                                    </div>
                                                </li>
                                                <li className="package-card-sm">
                                                    <div className="p-sm-img">
                                                        <img src={pm_sm_4} alt="" />
                                                    </div>
                                                    <div className="package-info">
                                                        <h3><i className="flaticon-arrival" />
                                                            <Link to={`${process.env.PUBLIC_URL}/centro_historico`}>{t("pachacamac_57")}</Link>
                                                        </h3>
                                                        <h5><span>$95</span>/ {t("pachacamac_55")}</h5>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

export default withHooks(Pachacamac);
