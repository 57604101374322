import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {Link} from "react-router-dom";
import ModalVideo from 'react-modal-video'

//Import Images
import features1Img from "../../../assets/images/feature/f-1.png"
import features2Img from "../../../assets/images/feature/f-2.png"

class Features extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpen: false,
    };
  }
  render() {
      const {isOpen } = this.state;

      const featuresOptions = {
          items: 2,
          loop: true,
          margin:30,
          smartSpeed: 1500,
          autoplay: false,
          dots: false,
          nav: true,
          navText : ["<i class='bx bx-chevron-left' ></i>","<i class='bx bx-chevron-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  dots : false
              },
              600:{
                  items:1,
                  nav:false,
                  dots : false,
              },
              1000:{
                  items:2,
                  dots: false,
                  nav: false,
                  loop:true
              }
          }
      };

    return (
       <>
           {/* ===============  Feature area start =============== */}
           <div className="feature-area-home mt-150 p-80">
           <div className="about-wrapper mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-md-12">
                           <div className="section-head pb-60">
                                <div className="about-video" 
                                     style={{"height":"200px"}}>
                                    <i onClick={() => this.setState({ isOpen: true })} 
                                        class="flaticon-play-button-arrowhead"></i>
                                </div>
                           </div>
                       </div>
                   </div>
               </div>
            </div>
           </div>

           <React.Fragment>
           <ModalVideo channel='youtube' 
                       autoplay 
                       isOpen={isOpen} 
                       videoId="hXkKDKXZg14" 
                       onClose={() => this.setState({ isOpen: false })} />
           </React.Fragment>
           {/* ===============  Feature area start =============== */}
       </>
    );
  }
}

export default Features;
