import React, { Component } from "react";
import ModalVideo from 'react-modal-video'

import about1Img from "../../../assets/images/about-1.png"
import about2Img from "../../../assets/images/about-2.png"
import pd_thumb from "../../../assets/images/perubike_images/recorte_26.jpg"
import pd_thumb_2 from "../../../assets/images/perubike_images/recorte_27.png"
import {Link} from "react-router-dom";

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class AboutWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

  render() {
      const {isOpen } = this.state;
      const {t} = this.props;
    return (
       <>
           {/* ===============  About wrapper area start =============== */}
           <div className="about-wrapper mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-7 col-md-12">
                           <div className="about-wrapper-left">
                               <div className="about-img">
                                   <img src={pd_thumb} alt="" className="img-fluid" />
                               </div>
                               <div className="about-video">
                                   <img src={pd_thumb_2} alt="" className="img-fluid"  />
                                   <i onClick={() => this.setState({ isOpen: true })} class="flaticon-play-button-arrowhead"></i>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-5 col-md-12">
                           <div className="about-wrapper-right section-head head-left">
                               <h5>{t("about_wrapper_3")}</h5>
                               <h2>{t("about_wrapper_4")}</h2>
                               <p>{t("about_wrapper_5")}</p>
                                <p>
                                {t("about_wrapper_6")}</p>
                               <ul className="about-list">
                                   <li><i className="flaticon-double-checking" />{t("about_wrapper_7")}</li>
                                   <li><i className="flaticon-double-checking" />{t("about_wrapper_8")}</li>
                                   <li><i className="flaticon-double-checking" /> {t("about_wrapper_9")}</li>
                                   <li><i className="flaticon-double-checking" /> {t("about_wrapper_10")}</li>
                               </ul>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

           <React.Fragment>
           <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="hXkKDKXZg14" onClose={() => this.setState({ isOpen: false })} />
           </React.Fragment>

           {/* ===============  About wrapper area end =============== */}
       </>
    );
  }
}

export default withHooks(AboutWrapper);
