import React, { Component } from "react";
import {Link,NavLink}               from "react-router-dom";

//Import Image
import logoMain             from "../../assets/images/logo.png"
import secondLogo           from "../../assets/images/logo-2.png"
import pd_thumb from "../../assets/images/perubike_images/tripadvisor.svg"

import Select from "react-select";

import i18n from "i18next";

import "../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

function handleChanged(event) {
    i18n.changeLanguage(event.value);
    // window.location.reload();
}

class Headers extends Component {
    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        const {t} = this.props;
        return (
            <>
            <div className="topbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 tob-contact-row">
                            <div className="topbar-contact">
                                <ul>
                                    <li>
                                        <i className="bx bxs-phone" />
                                        <a href="tel:+51 998 724 021">+51 998 724 021</a>
                                    </li>

                                    <li>
                                        <i className="bx bxs-envelope" />
                                        <a href="mailto:info@perubike.com">info@perubike.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                            <div className="topbar-social">
                                <ul>
                                    <li>
                                        <Link to={{pathname:"https://www.facebook.com/perubiketours/"}} target="_blank">
                                            <i className="bx bxl-facebook" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname:"https://www.instagram.com/perubike?igsh=MTIwNTg3OTUwNmttMg=="}} target="_blank">
                                            <i className="bx bxl-instagram" /></Link>
                                        </li>
                                    <li>
                                        <Link to={{pathname:"https://www.tripadvisor.com.pe/Attraction_Review-g294316-d1590736-Reviews-Perubike-Lima_Lima_Region.html"}} target="_blank">
                                            <img src={pd_thumb} style={{"width": "1.2rem", "padding-bottom": "0.2rem"}}/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-3 col-sm-3 col-3">
                            {/* <div className="custom-select languege-select"> */}
                            {/* <div className=""> */}
                                <Select
                                    options={[
                                        { value: 'en', label: 'EN' },
                                        { value: 'fr', label: 'FR' }
                                    ]}
                                    defaultValue={{ value: 'en', label: 'EN' }}
                                    color="transparent"
                                    // placeholder="Select Language, default English"
                                    styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: 'transparent',
                                        //   borderColor: 'transparent',
                                        //   width: '35%',
                                        //   height: '30px',
                                        }),
                                        input: (styles) =>({
                                                ...styles,
                                                color: 'white',
                                                width: '100%',
                                        }),
                                        singleValue: (styles) => ({ 
                                            ...styles,
                                             color: 'white' 
                                        }),
                                        option: (styles) =>({
                                            ...styles,
                                            // width: '35%',
                                        })
                                             
                                      }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={handleChanged}

                                />
                            {/* </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <div className="header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                <div className="navbar-wrap">
                                    <div className="logo d-flex justify-content-between">
                                        <Link to={`${process.env.PUBLIC_URL}/`} className="navbar-brand" onClick={this.scrollTop}><img src={logoMain} alt="" /></Link>
                                    </div>
                                    <div className="navbar-icons">
                                        <div className="searchbar-open">
                                            <i className="flaticon-magnifier" />
                                        </div>
                                        <div className="mobile-menu d-flex ">
                                            <div className="top-search-bar m-0 d-block d-xl-none">
                                            </div>
                                            <Link to={"#"} className="hamburger d-block d-xl-none">
                                                <span className="h-top" />
                                                <span className="h-middle" />
                                                <span className="h-bottom" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <nav className="main-nav">
                                    <div className="navber-logo-sm">
                                        <img src={secondLogo} alt="" className="img-fluid" />
                                    </div>
                                    <ul>
                                        <li>
                                            <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/`} className="sub-item" onClick={this.scrollTop}>{t("header_7")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink  activeClassName="active" to={`${process.env.PUBLIC_URL}/about-us`} onClick={this.scrollTop} >{t("header_8")}</NavLink >
                                        </li>
                                        <li className="has-child-menu">
                                            <Link to={"#"}>{t("header_9")}</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                <li>
                                                    <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/centro_historico`} className="sub-item" onClick={this.scrollTop}>{t("header_11")}</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/lima_bahia`} className="sub-item" onClick={this.scrollTop}>{t("header_12")}</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/lima_bohemian`} className="sub-item" onClick={this.scrollTop}>{t("header_13")}</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/pachacamac`} className="sub-item" onClick={this.scrollTop}>Pachacamac</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to={`${process.env.PUBLIC_URL}/contact`} onClick={this.scrollTop} >{t("header_15")}</NavLink>
                                        </li>
                                    </ul>
                                    <div className="sidebar-contact">
                                        <ul>
                                            <li className="sidebar-single-contact"><i className="bx bxs-phone" />
                                                <Link to={`tel:+17632275032`} >+1 763-227-5032</Link>
                                            </li>
                                            <li className="sidebar-single-contact"><i className="bx bxs-envelope" />
                                                <Link to={`mailto:info@example.com`} >info@perubike.com</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="main-searchbar">
                            <div className="searchbar-close">
                                <i className="bx bx-x" />
                            </div>
                            <input type="text" placeholder="Search Here......" />
                            <div className="searchbar-icon">
                                <i className="bx bx-search" />
                            </div>
                        </div>
                    </form>

                </div>
            </header>
            </>
        );
    }
}

export default withHooks(Headers);
