import React, { Component } from "react";
import {Link} from "react-router-dom";
import pd_thumb from "../../../assets/images/perubike_images/tripadvisor.svg"

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class AboutUs extends Component {
    componentDidMount(){
        this.scrollTop();
    }

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  render() {
    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t("header_15")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="contact-wrapper pt-90">
                <div className="contact-cards">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="contact-card">
                                    <div className="contact-icon"><i className="flaticon-arrival" />
                                    </div>
                                    <div className="contact-info">
                                        <h5>{t("address")}</h5>
                                        <p>Punta Sal 506, Urb. Nueva Castilla, Surco. Lima - Perú</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="contact-card">
                                    <div className="contact-icon"><i className="flaticon-customer-service" />
                                    </div>
                                    <div className="contact-info">
                                        <h5>{t("email")} &amp; {t("phone")}</h5>
                                        <p>998 724 021
                                        info@perubike.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="contact-card">
                                    <div className="contact-icon"><i className="flaticon-thumbs-up" />
                                    </div>
                                    <div className="contact-info">
                                        <h5>{t("social_media")}</h5>
                                        <ul className="contact-icons">
                                        <li>
                                            <Link to={{pathname:"https://www.facebook.com/perubiketours/"}} target="_blank">
                                                <i className="bx bxl-facebook" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{pathname:"https://www.instagram.com/perubike?igsh=MTIwNTg3OTUwNmttMg=="}} target="_blank">
                                                <i className="bx bxl-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{pathname:"https://www.tripadvisor.com.pe/Attraction_Review-g294316-d1590736-Reviews-Perubike-Lima_Lima_Region.html"}} target="_blank">
                                                <img src={pd_thumb} style={{"width": "1.2rem", "padding-bottom": "0.2rem"}}/>
                                            </Link>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="contact-inputs pt-100">
                        <div className="row" style={{"display": "flex", "justify-content": "center", "align-items": "center"}}>
                            <div className="col-lg-6">
                                <div className="contact-details" >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

export default withHooks(AboutUs);
