import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Images
import review1Img from "../../../assets/images/reviewer/reviewer-1.png"
import review2Img from "../../../assets/images/reviewer/reviewer-2.png"
import review3Img from "../../../assets/images/reviewer/reviewer-3.png"

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }

class AboutReview extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {

      const reviewOptions = {
          stagePadding: 10,
          items: 3,
          loop: true,
          margin:15,
          smartSpeed: 1500,
          autoplay: false,
          dots: true,
          nav: true,
          navText : ["<i class='bx bx-chevron-left' ></i>","<i class='bx bx-chevron-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  dots : false
              },
              800:{
                  items:2,
                  nav:false,
                  dots : false,
              },
              1000:{
                  items:3,
                  dots: true,
                  nav:false,
                  loop:true
              }
          }
      };

      const {t} = this.props;

    return (
       <>
           <div className="review-area mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="section-head pb-40">
                               <h5>{t("about_review_2")}</h5>
                               <h2>{t("about_review_3")}</h2>
                           </div>
                       </div>
                   </div>
                   <OwlCarousel className="review-slider owl-carousel"  {...reviewOptions}>
                       <div className="review-card ">
                           <div className="reviewer-info">
                               <h3>Julio E.</h3>
                               <h5>{t("about_review_5")}</h5>
                               <p>{t("about_review_6")}</p>
                           </div>
                       </div>
                       <div className="review-card">
                           <div className="reviewer-info">
                               <h3>Fiorella C.</h3>
                               <h5>{t("about_review_8")}</h5>
                               <p>{t("about_review_9")}</p>
                           </div>
                       </div>
                       <div className="review-card">
                           <div className="reviewer-info">
                               <h3>ALFONSO E.</h3>
                               <h5>{t("about_review_11")}</h5>
                               <p>{t("about_review_12")}</p>
                           </div>
                       </div>
                       <div className="review-card ">
                           <div className="reviewer-info">
                               <h3>Paola M.</h3>
                               <h5>{t("about_review_14")}</h5>
                               <p>{t("about_review_15")}</p>
                           </div>
                       </div>
                       <div className="review-card">
                           <div className="reviewer-info">
                               <h3>Carmen F M.</h3>
                               <h5>{t("about_review_17")}
                               </h5>
                               <p>{t("about_review_18")}</p>
                           </div>
                       </div>
                       <div className="review-card">
                           <div className="reviewer-info">
                               <h3>PatadePerroPeru</h3>
                               <h5>{t("about_review_20")}</h5>
                               <p>{t("about_review_21")}</p>
                           </div>
                       </div>
                   </OwlCarousel>
               </div>
           </div>
       </>
    );
  }
}

export default withHooks(AboutReview);
