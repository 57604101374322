import React, { Component } from "react";

import "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


function withHooks(WrappedComponent) {
    return function(props) {
      const {t} = useTranslation();
      return (
        < WrappedComponent t={t} {...props} />
      );
    };
  }


class Achievement extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {

    const {t} = this.props;
    return (
       <>
           {/* =============== achievement area start =============== */}
           <div className="achievement-area p-80 mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="section-head pb-30">
                               <h5>{t("achievement_1")}</h5>
                               <h2>{t("achievement_2")}</h2>
                           </div>
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                           <div className="achievement-card mt-30" style={{"height":"400px"}}>
                               <div className="achievement-icon">
                                   <i className="flaticon-guide" />
                               </div>
                               <h5>{t("achievement_3")}</h5>
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="200ms">
                           <div className="achievement-card mt-30" style={{"height":"400px"}}>
                               <div className="achievement-icon">
                                   <i className="flaticon-trust" />
                               </div>
                               <h5>{t("achievement_4")}</h5>
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="400ms">
                           <div className="achievement-card mt-30" style={{"height":"400px"}}>
                               <div className="achievement-icon">
                                   <i className="flaticon-experience" />
                               </div>
                               <h5>{t("achievement_5")}</h5>
                           </div>
                       </div>
                       <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" 
                            data-wow-duration="1500ms" 
                            data-wow-delay="600ms">
                           <div className="achievement-card mt-30" style={{"height":"400px"}}>
                               <div className="achievement-icon">
                                   <i className="flaticon-traveller" />
                               </div>
                               <h5>{t("achievement_6")}</h5>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           {/* =============== achievement area end =============== */}
       </>
    );
  }
}

export default withHooks(Achievement);
